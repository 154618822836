/*eslint-disable*/
import axios from 'axios';

function socialLogin(provider, response) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.VUE_APP_API_URL}/sociallogin/${provider}`, response)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function authProvider(provider, userRole) {
  return new Promise((resolve, reject) => {
    this.$auth
      .authenticate(provider)
      .then(async (response) => {
        response['userRole'] = userRole;
        await socialLogin(provider, response)
          .then((resp) => {
            resolve(resp);
          })
          .catch((error) => {
            resolve(false);
          });
      })
      .catch((err) => {
        this.isFormSubmit = false;
        resolve(false);
      });
  });
}
