import { io } from 'socket.io-client';
import store from '../store/index';

class SocketioService {
  socket;

  setupSocketConnection() {
    this.socket = io(process.env.VUE_APP_SOCKET_ENDPOINT);
    let socketId = '';
    let logginUserId = 0;
    this.socket.on('connect', () => {
      socketId = this.socket.id;
      const userdata = JSON.parse(localStorage.getItem('userData'));
      if (userdata) {
        logginUserId = userdata.id;
      }
      if (logginUserId > 0 && socketId) {
        this.socket.emit('user_online', {
          socket_id: socketId,
          login_id: logginUserId,
        });
      }
      this.socket.on('online_users_list', (data) => {
        // this.$Onlineusers = [...new Set(data.map((value) => value.login_id))];
        store.commit('ChangeOnlineUserList', data);
      });
    });
    // eslint-disable-next-line no-unused-vars
    this.socket.on('chat_message', (data) => {});
  }

  sendMessage(msgdata) {
    if (msgdata) {
      const {
        senderId, receiverId, bookingId, message, messageType,
      } = msgdata;
      if (
        senderId > 0 && receiverId > 0 && bookingId > 0 && (message || messageType === 'image' || messageType === 'file')
      ) {
        this.socket.emit('chat_message', {
          sender_id: senderId,
          reciver_id: receiverId,
          booking_id: bookingId,
          from: 'customer',
          message,
          attechment_name: msgdata.attechment_name,
          attechment_extension: msgdata.attechment_extension,
          attechment_mime_type: msgdata.attechment_mime_type,
          message_type: msgdata.messageType,
          attechment_url: msgdata.attechment_url,
          sender_name: msgdata.sender_name,
        });
      }
    }
  }

  readReceipt(readdata) {
    if (readdata) {
      const { senderId, receiverId, bookingId } = readdata;
      if (senderId > 0 && receiverId > 0 && bookingId > 0) {
        this.socket.emit('read_receipts', {
          booking_id: bookingId,
          sender_id: senderId,
          reciver_id: receiverId,
        });
      }
    }
  }
  // sendMessage() {
  //   this.socket.emit('chat_message', {
  //     booking_id: 45,
  //     sender_id: 4,
  //     reciver_id: 3,
  //     message: new Date().toLocaleString(),
  //     attechment_name: '',
  //     attechment_extension: '',
  //     attechment_mime_type: '',
  //     message_type: 'text',
  //     attechment_url: null,
  //   });
  // }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}

export default new SocketioService();
