import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import i18n from '@/libs/i18n';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import * as VueGoogleMaps from 'vue2-google-maps';
import vSelect from 'vue-select';
import VCalendar from 'v-calendar';
import SocketioService from '@/services/socketio.service';
import VueSocialauth from 'vue-social-auth';
import OneSignalVue from 'onesignal-vue';
import VueSweetalert2 from 'vue-sweetalert2';
import { VBTooltip } from 'bootstrap';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

// valdation

import Vuelidate from 'vuelidate';

import { authProvider } from '@/views/SocialLogin';
import bootstrapBundle from '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import App from './App.vue';
import router from './router';
import store from './store';

// 3rd party plugins
import '@axios';
import '@/libs/acl';

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_KEY,
    libraries: 'places',
  },
  installComponents: true,
});
Vue.use(VueSocialauth, {
  providers: {
    google: {
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      // client_secret: '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',
      redirectUri: process.env.VUE_APP_GOOGLE_CALLBACK_URL,
    },
    facebook: {
      clientId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
      // client_secret: 'ccfdb08fa592abc2d810052c86ee0030',
      redirectUri: process.env.VUE_APP_FACEBOOK_CALLBACK_URL,
    },
  },
});

Vue.use(VCalendar);
Vue.use(OneSignalVue);

Vue.component('v-select', vSelect);

Vue.use(Vuelidate);
Vue.use(VueToast);
Vue.directive('b-tooltip', VBTooltip);

// require('bootstrap');

library.add(fas);
library.add(fab);
dom.watch();

Vue.use(bootstrapBundle);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// import assets styles
require('@assets/scss/style.scss');

Vue.config.productionTip = false;
Vue.prototype.$socket = SocketioService;

Vue.mixin({
  methods: {
    authProvider,
  },
});

Vue.use(VueSweetalert2);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  mounted() {
    // this.stripeActive();
    // this.$OneSignal.init({ appId: '7698d72a-4106-440c-ac35-1bb8e52ac593' });
    this.$OneSignal.init({ appId: process.env.VUE_APP_ONESIGNAL_APP_ID }).then(() => {
      // this.$OneSignal.showHttpPrompt(true);
      this.$OneSignal.showNativePrompt(true);
    });

    // this.$OneSignal.on('subscriptionChange', (isSubscribed) => {
    // });
    /* eslint-disable */

    window.OneSignal.push(() => {
      window.OneSignal.on('notificationDisplay', (event) => {
        const redirectTo = event.data.redirect;
        if (redirectTo === 'booking-detail' && this.$route.name !== 'bookings' && (event.data.booking_data.booking_status == 0 || event.data.booking_data.booking_status == 2)) {
          this.alert(event.data.booking_data);
        }
      });
    });
    window.OneSignal.push([
      'addListenerForNotificationOpened',
      (data) => {
        const redirectTo = data.data.redirect;
        if (redirectTo === 'chat') {
          this.$router.push({
            name: 'message',
            params: { bookingId: data.data.booking_id, sender_id: data.data.sender_id },
          });
        }
        if (redirectTo === 'booking-detail') {
          this.$router.push({ name: 'bookings', params: { bookingId: data.data.booking_id } });
        }
      },
    ]);
    // this.$OneSignal.on('notificationDisplay', (event) => {
    // });
  },

  methods: {
    // stripeActive(){
    //   localStorage.setItem('isStripeActive',0);
    //   this.$http
    //   .get('/admin/setting-list')
    //   .then((res) => {
    //     var data1 = res.data.data;
    //     if(data1.some(e => (e.key === 'stripe' && JSON.parse(e.value).status == 1))){
    //       localStorage.setItem('isStripeActive',1);
    //     }
    //   })
    //   .catch((error) => {
    //   })
    //   .finally(() => {
    //   });
    // },

    alert(booking_data) {
      if(booking_data.booking_status == 0){
          var text =  `Driver place bid on booking ${booking_data.booking_reference_id}.`;
      }
      if(booking_data.booking_status == 2){
          var text =  `Booking ${booking_data.booking_reference_id} has completed.`;
      }
      this.$swal
        .fire({
          html: `${text}`,
          icon: 'info',
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: 'View booking',
          cancelButtonText: 'Close',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$router.push({
              name: 'bookings',
              params: { from: 'alert-modal',booking_id: booking_data.id },
            });
          }
        });
    },
  },
}).$mount('#app');
