/* eslint-disable */
import Vue from 'vue';
import VueRouter from 'vue-router';
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils';

Vue.use(VueRouter);

const routes = [
  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "login" */ '@/views/sessions/Account.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password/:id',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "resetPassword" */ '@/views/sessions/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  // {
  //   path: '/account/login',
  //   name: 'login',
  //   component: () => import(/* webpackChunkName: "login" */ '@/views/sessions/Login.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  // {
  //   path: '/account/signup',
  //   name: 'signup',
  //   component: () =>
  //     import(/* webpackChunkName: "CustomerSignup" */ '@/views/sessions/CustomerSignup.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  {
    path: '/driver/signup',
    name: 'driver-signup',
    component: () =>
      import(/* webpackChunkName: "DriverSignup" */ '@/views/sessions/DriverSignup.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/driver/signup-2',
    name: 'driver-signup-2',
    component: () =>
      import(/* webpackChunkName: "DriverSignup2" */ '@/views/sessions/DriverSignup2.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/driver/download-app',
    name: 'driver-download-app',
    component: () =>
      import(/* webpackChunkName: "DriverSignupDone" */ '@/views/sessions/DriverSignupDone.vue'),
    meta: {
      layout: 'full',
      // resource: 'Auth',
      // redirectIfLoggedIn: true,
    },
  },
  {
    path: '/',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/pages/Dashboard.vue'),
  },
  {
    path: '/Confirmation',
    name: 'confirmation',
    component: () => import('../views/pages/Confirmation.vue'),
  },
  {
    path: '/edit/profile',
    name: 'editprofile',
    component: () => import(/* webpackChunkName: "editprofile" */ '../views/pages/EditProfile.vue'),
  },
  {
    path: '/change/password',
    name: 'changePassword',
    component: () =>
      import(/* webpackChunkName: "changepassword" */ '../views/pages/ChangePassword.vue'),
  },
  {
    path: '/payment/details',
    name: 'paymentDetails',
    component: () =>
      import(/* webpackChunkName: "paymentdetails" */ '../views/pages/paymentDetails.vue'),
  },
  {
    path: '/bookings',
    name: 'bookings',
    component: () => import(/* webpackChunkName: "bookings" */ '../views/pages/Bookings.vue'),
  },
  {
    path: '/payment/:bookingId/:bidId',
    name: 'payment',
    component: () => import(/* webpackChunkName: "payment" */ '../views/pages/Payment.vue'),
  },
  {
    path: '/request-driver',
    name: 'request-driver',
    component: () =>
      import(/* webpackChunkName: "requestdriver" */ '../views/pages/RequestDriver.vue'),
  },
  {
    path: '/edit-booking/:booking_id',
    name: 'edit-booking',
    component: () =>
      import(/* webpackChunkName: "requestdriver" */ '../views/pages/EditBooking.vue'),
  },
  // {
  //   path: '/map',
  //   name: 'map',
  //   component: () => import(/* webpackChunkName: "map" */ '../views/pages/Map.vue'),
  // },
  {
    path: '/message',
    name: 'message',
    component: () => import(/* webpackChunkName: "message" */ '../views/pages/Message.vue'),
  },
  {
    path: '/misc-not-authorized',
    name: 'misc-not-authorized',
    component: () =>
      import(/* webpackChunkName: "not-authorized" */ '../views/sessions/NotAuthorized.vue'),
  },
  {
    path: '/support',
    name: 'support',
    component: () => import(/* webpackChunkName: "support" */ '../views/pages/Ticket/Support.vue'),
  },
  {
    path: '/new-ticket',
    name: 'new-ticket',
    component: () =>
      import(/* webpackChunkName: "new-ticket" */ '../views/pages/Ticket/NewTicket.vue'),
  },
  {
    path: '/ticketShow/:id',
    name: 'ticketShow',
    component: () =>
      import(/* webpackChunkName: "view-ticket" */ '../views/pages/Ticket/ViewTicket.vue'),
  },
  {
    path: '/driver/booking',
    name: 'driverbooking',
    component: () =>
      import(/* webpackChunkName: "driver-booking" */ '../views/pages/DriverBooking.vue'),
  },
  {
    path: '/driver/download-app-from-dashboard',
    name: 'driver-download-app-from-dashboard',
    component: () =>
      import(
        /* webpackChunkName: "driver-download-app-from-dashboard" */ '@/views/sessions/DriverSignupDone.vue'
      ),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/driver/list',
    name: 'searchresult',
    component: () =>
      import(
        /* webpackChunkName: "search-result" */ '../views/pages/DashboardSearchComponents/SearchResult.vue'
      ),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  const allowedRoutes = [
    'account',
    'reset-password',
    // 'signup',
    'driver-signup',
    'driver-signup-2',
    'driver-download-app',
  ];
  if (!isLoggedIn && allowedRoutes.includes(to.name)) return next();
  if (!isLoggedIn && to.name == 'reset-password') return next({ name: 'reset-password' });
  if (!isLoggedIn && to.name != 'account') return next({ name: 'account' });

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next({ name: getHomeRouteForLoggedInUser(userData ? userData.id : null) });
  }

  return next();
});

export default router;
