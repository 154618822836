<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import { Tooltip } from 'bootstrap';
import { mapActions } from 'vuex';
import { isUserLoggedIn, getUserData } from '@/auth/utils';

const LayoutSidebar = () => import('@/components/layouts/LayoutSidebar.vue');
const LayoutFull = () => import('@/components/layouts/LayoutFull.vue');

export default {
  name: 'App',
  components: {
    LayoutSidebar,
    LayoutFull,
  },
  created() {
    this.$socket.setupSocketConnection();
    // eslint-disable-next-line no-new
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });
    document.addEventListener('DOMSubtreeModified', (e) => {
      const ele = document.querySelectorAll("[data-bs-toggle='tooltip']");
      ele.forEach((tempEle) => {
        tempEle.addEventListener('click', (event) => {
          const tooltip = Tooltip.getOrCreateInstance(tempEle);
          tempEle.blur();
          tooltip.hide();
        });
      });
    });
    if (isUserLoggedIn()) {
      const user = getUserData();
      this.connectedList();
      this.updateLastActive();
      this.$socket.socket.onAny((eventName) => {
        if (user.id && eventName.startsWith(`sendto_${user.id}_`)) {
          this.connectedList();
        }
      });
    }
  },
  beforeUnmount() {
    this.$socket.disconnect();
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full';
      if (isUserLoggedIn()) return 'layout-sidebar';
      return 'layout-full';
    },
  },
  methods: {
    ...mapActions(['connectedList', 'updateLastActive']),
  },
};
</script>
