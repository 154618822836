import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@axios';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    Onlineusers: [],
    unreadMessageCount: 0,
  },
  mutations: {
    ChangeOnlineUserList(state, data) {
      state.Onlineusers = [
        // ...new Set(data.filter((item) => item.socket_id != null).map((value) => value.login_id)),
        ...new Set(data.map((value) => value.login_id)),
      ];
    },
    setUnreadMessageCount(state, data) {
      state.unreadMessageCount = data;
    },
  },
  actions: {
    connectedList({ commit }) {
      axios
        .get('/chat/connected_driver_list')
        .then((res) => {
          const tempdata = res.data.data.filter((e) => e.count_unread_message > 0);
          commit('setUnreadMessageCount', tempdata.length);
        })
        .catch((error) => {
          console.log(error);
          this.connectedDriver = [];
        })
        .finally(() => {});
    },
    updateLastActive() {
      axios
        .get('/customer/updateLastActive')
        .then(() => {
        })
        .catch(() => {
        });
    },
  },
  modules: {},
});

export default store;
